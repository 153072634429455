import { Component, computed, ContentChild, input, Input, TemplateRef } from '@angular/core';
import { Column, ColumnType } from '../../../model';
import { get } from 'lodash';

@Component({
  selector: 'raily-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss'
})
export class TableComponent {
  @ContentChild(TemplateRef) templateRef!: TemplateRef<any>;

  @Input() items!: any[];
  @Input() columns!: Column[];
  @Input() filterFields = ['id', 'name', 'tag'];
  @Input() fixedLayout = true;
  @Input() sortable = true;
  @Input() showButtonsColumn = false;
  @Input() title?: string;
  @Input() editable= false;
  @Input() addingRow = false;
  scrollHeight = input<string>();
  fromPageSizeMargin = input<number>(250);

  finalScrollHeight = computed(() => {
    if (this.scrollHeight()) {
      return this.scrollHeight();
    }
    return `calc(100vh - ${this.fromPageSizeMargin()}px)`;
  });

  trackBy(index: number, item: any) {
    return item.id || index;
  }

  getValue(item: any, path: string) {
    return get(item, path);
  }

  getSelectValue(item: any, column: Column) {
    return column.selectOptions?.find(option => option.value === this.getValue(item, column.propertyPath))?.label;
  }

  getColumnType(column: Column) {
    return column.type ?? ColumnType.Text;
  }

  getColumnSortable(column: Column) {
    return column.sortable ?? true;
  }

  onCellEdit(item: any, column: Column) {
    if (column.editCallback) {
      column.editCallback(item, column);
    }
  }

  protected readonly ColumnType = ColumnType;
}
