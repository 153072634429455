import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import {
  ControlValueAccessor, FormBuilder,
  FormControl, FormGroup,
  NgControl,
  Validators
} from '@angular/forms';
import { Company } from '../../../model';
import { BehaviorSubject, delay, Observable } from 'rxjs';

@Component({
  selector: 'raily-company-input',
  templateUrl: './company-input.component.html',
  styleUrl: './company-input.component.scss'
})
export class CompanyInputComponent implements ControlValueAccessor, AfterViewInit, OnInit  {
  @Input() required = false;
  @Input() label: string = 'SHARED.COMPANY.DEFAULT_NAME';
  value?: Company;
  onChange = (_: string) => {};
  onTouch = () => {};

  formControl: FormControl;
  form!: FormGroup;

  private valueChangesSubject = new BehaviorSubject(this.value);
  valueChanges: Observable<any> = this.valueChangesSubject.pipe(
    delay(1)
  );

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    ngControl.valueAccessor = this;
    this.formControl = ngControl.control as FormControl;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      shortName: this.fb.control(null),
      ricsCode: this.fb.control(null),
    });
  }

  onBlur(control: 'ricsCode' | 'shortName') {
    const inputValue = this.form.get(control)!.value;
    let controlValue = this.formControl.value ?? { ricsCode: '', shortName: '' };
    if (typeof inputValue === 'string') {
      controlValue[control] = inputValue;
    } else {
      controlValue = inputValue;
    }
    this.formControl.setValue(controlValue);
    if (this.required && (this.formControl.value === null || !this.formControl.value.shortName || !this.formControl.value.ricsCode)) {
      this.formControl.setErrors({ required: true });
    } else {
      this.formControl.setErrors(null);
    }
  }

  ngAfterViewInit(): void {
    this.formControl = this.ngControl?.control as FormControl;
    if (this.required) {
      this.formControl.addValidators(Validators.required);
    }
    this.formControl.valueChanges.subscribe(value => {
      this.form.get('shortName')!.setValue(value, { emitEvent: false });
      this.form.get('ricsCode')!.setValue(value, { emitEvent: false });
      this.valueChangesSubject.next(value);
    })
    this.cdr.detectChanges();
  }

  writeValue(value: Company): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form?.disable();
    } else {
      this.form?.enable();
    }
  }
}
