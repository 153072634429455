<div>
  <div class="flex flex-row mb-3" style="transform: translateY(4px)">
    <label [for]="label | transloco" class="font-medium text-900">
      {{ label | transloco }}
      <span *ngIf="required">*</span>
    </label>
  </div>

  <form [formGroup]="form" class="flex flex-row gap-2">
    <div class="w-3">
      <raily-search-autocomplete
        #input
        [label]="'SHARED.COMPANY.RICS_CODE'"
        [url]="'companies/search'"
        [apiSearch]="true"
        [labelField]="'ricsCode'"
        [required]="required"
        [showRequiredAsterisks]="false"
        (blur)="onBlur('ricsCode')"
        formControlName="ricsCode">
      </raily-search-autocomplete>
    </div>

    <div class="w-9">
      <raily-search-autocomplete
        #input
        [label]="'SHARED.COMPANY.NAME'"
        [url]="'companies/search'"
        [apiSearch]="true"
        [labelField]="'shortName'"
        [required]="required"
        [showRequiredAsterisks]="false"
        (blur)="onBlur('shortName')"
        formControlName="shortName">
      </raily-search-autocomplete>
    </div>
  </form>
</div>
