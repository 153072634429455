import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceOrderLinkService {

  dataFromDamageReport?: DataFromDamageReport | null;
}

export interface DataFromDamageReport {
  damageReportId: string;
  assetId: string;
}
