import { map, Observable, of, tap } from "rxjs";
import { Injectable } from "@angular/core";

import { HttpService } from "../http/http.service";

@Injectable()
export abstract class BaseApiService<T, R = T> {
  protected endpoint = '';
  protected cache = {};

  protected constructor(
    protected http: HttpService,
  ) {}

  find(query?: any): Observable<T[]> {
    return this.http.get<T[]>(this.endpoint, query).pipe(
      map(entities => {
        return entities.map(entity => {
          // @ts-ignore
          if (entity['internalId']) {
            // @ts-ignore
            entity['id'] = entity['internalId'];
          }
          return entity;
        })
      })
    )
  }

  getById(id: string, withCache?: boolean): Observable<T> {
    if (withCache && this.cache[id]) {
      return of(this.cache[id]);
    }
    return this.http.get<T>(`${this.endpoint}/${id}`).pipe(
      tap(result => {
        this.cache[id] = result;
      })
    )
  }

  create(entity: Partial<R>): Observable<T> {
    return this.http.post<T, Partial<R>>(this.endpoint, entity);
  }

  update(id: string, entity: Partial<R>): Observable<T> {
    return this.http.patch<T, Partial<R>>(`${this.endpoint}/${id}`, entity);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.endpoint}/${id}`);
  }

  clearCache() {
    this.cache = {};
  }
}
