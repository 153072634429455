import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export abstract class BaseDtoService<T, R = T> {
  abstract toDto(model: T): R;
  abstract initModel(): Partial<T>;

  fromDto(dto: R): Observable<T> {
    return of(dto as unknown as T);
  }
}
