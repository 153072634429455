import { Routes } from '@angular/router';
import { guestGuard, loggedInGuard } from '@raily/auth';
import { SettingsComponent } from '@raily/core';
import { LoginComponent } from '@raily/auth';
import { APP_ROUTES } from '@raily/shared';
import { HomepageComponent } from '@raily/core';

export enum AppRoutes {
  Assets = 'assets',
  AssetTemplates = 'asset-templates',
  Elements = 'elements',
  Attributes = 'attributes',
  Dictionaries = 'dictionaries',
  DocumentTypes = 'document-types',
  Organisation = 'organisation',
  Roles = 'roles',
  Users = 'users',
  DamageReports = 'damage-reports',
  TrainComposition = 'train-composition',
  MaintenanceOrders = 'maintenance-orders',
  Contractors = 'contractors',
  MaintenanceSteps = 'maintenance-steps',
  Settings = 'settings',
  Login = 'auth/login',
  ResetPassword = 'reset-password',
  Home = 'home'
}

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: AppRoutes.Home,
  },
  {
    path: AppRoutes.Login,
    component: LoginComponent,
    canActivate: [guestGuard]
  },
  {
    path: AppRoutes.ResetPassword,
    component: LoginComponent,
    canActivate: [guestGuard]
  },
  {
    path: AppRoutes.Settings,
    component: SettingsComponent,
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.Home,
    component: HomepageComponent,
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.Assets,
    loadChildren: () =>
      import('@raily/asset').then(
        (m) => m.AssetModule
      ),
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.AssetTemplates,
    loadChildren: () =>
      import('@raily/asset-template').then(
        (m) => m.AssetTemplateModule
      ),
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.Elements,
    loadChildren: () =>
      import('@raily/element').then(
        (m) => m.ElementModule
      ),
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.Attributes,
    loadChildren: () =>
      import('@raily/attribute').then(
        (m) => m.AttributeModule
      ),
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.Dictionaries,
    loadChildren: () =>
      import('@raily/dictionary').then(
        (m) => m.DictionaryModule
      ),
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.DocumentTypes,
    loadChildren: () =>
      import('@raily/document-type').then(
        (m) => m.DocumentTypeModule
      ),
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.Organisation,
    loadChildren: () =>
      import('@raily/organisation').then(
        (m) => m.OrganisationModule
      ),
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.Roles,
    loadChildren: () =>
      import('@raily/role').then(
        (m) => m.RoleModule
      ),
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.Users,
    loadChildren: () =>
      import('@raily/user').then(
        (m) => m.UserModule
      ),
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.DamageReports,
    loadChildren: () =>
      import('@raily/damage-report').then(
        (m) => m.DamageReportModule
      ),
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.TrainComposition,
    loadChildren: () =>
      import('@raily/train-composition').then(
        (m) => m.TrainCompositionModule
      ),
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.MaintenanceOrders,
    loadChildren: () =>
      import('@raily/maintenance-order').then(
        (m) => m.MaintenanceOrderModule
      ),
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.Contractors,
    loadChildren: () =>
      import('@raily/contractor').then(
        (m) => m.ContractorModule
      ),
    canActivate: [loggedInGuard]
  },
  {
    path: AppRoutes.MaintenanceSteps,
    loadChildren: () =>
      import('@raily/maintenance-step').then(
        (m) => m.MaintenanceStepModule
      ),
    canActivate: [loggedInGuard]
  },
];

export const appRoutesProvider = {
  provide: APP_ROUTES,
  useValue: AppRoutes,
};
